export default function symbolObservablePonyfill(root) {
  var result;
  var Symbol = root.Symbol;
  if (typeof Symbol === 'function') {
    if (Symbol.observable) {
      result = Symbol.observable;
    } else {
      if (typeof Symbol.for === 'function') {
        // This just needs to be something that won't trample other user's Symbol.for use
        // It also will guide people to the source of their issues, if this is problematic.
        // META: It's a resource locator!
        result = Symbol.for('https://github.com/benlesh/symbol-observable');
      } else {
        // Symbol.for didn't exist! The best we can do at this point is a totally 
        // unique symbol. Note that the string argument here is a descriptor, not
        // an identifier. This symbol is unique.
        result = Symbol('https://github.com/benlesh/symbol-observable');
      }
      try {
        Symbol.observable = result;
      } catch (err) {
        // Do nothing. In some environments, users have frozen `Symbol` for security reasons,
        // if it is frozen assigning to it will throw. In this case, we don't care, because
        // they will need to use the returned value from the ponyfill.
      }
    }
  } else {
    result = '@@observable';
  }
  return result;
}
;