@if (templates?.length) {
  <dpa-template-search-grid-list
    [pageSize]="10"
    [results]="templates"
    [isLoading]="false"
    [filterTags]="tags"
    [selectedTag]="selectedTag"
    [orderByArray]="orderByArray"
    [cardTemplate]="cardTemplate"
    [selectedRows]="[selectedTemplate]"
    [availableColumns]="templateTableColumns"
    [cellTemplatesByColumnValue]="cellTemplatesByColumnValue"
    [columnWidthByKey]="columnWidthByKey"
    [customContentTemplate]="showCustomContentTemplate ? blankTemplateButton : undefined"
    [searchExact]="searchExact"
    [searchOn]="searchOn"
    [searchPlaceholder]="'COMMON_MESSAGES.SEARCH_FOR_TEMPLATES' | translate"
    [actions]="actions"
    [updateUrlLayoutParam]="updateUrlLayoutParam"
    [tableName]="tableName"
    class="template-page template-datagrid"
  />
} @else {
  <ng-container [ngTemplateOutlet]="noResultsTemplate" />
}

<ng-template
  #cardTemplate
  let-template="row"
  let-styledString="styledString"
>
  <dpa-template-card
    [template]="template"
    [selectedTempalteId]="selectedTemplate?.id"
    [styledString]="styledString"
    [tags]="getTemplateTags(template)"
    [bookmarksEnabled]="bookmarksEnabled"
    [bookmarkInProgress]="bookmarkInProgress"
    [actions]="actions"
    [customCardBlock]="customCardBlock"
    [tagsTemplate]="tagsTemplate"
    [dropdownActions]="dropdownActions"
    (toggleBookmark)="onToggleBookmark($event)"
  />
</ng-template>

<ng-template #blankTemplateButton>
  <button
    (click)="clickCustomTemplate.emit()"
    class="btn btn-outline mr-0"
  >
    {{ 'TEMPLATE.ADD_CUSTOM_' + templateType | uppercase | translate }}
    <!-- possible values
    TEMPLATE.ADD_CUSTOM_AUTOMATIONS
    TEMPLATE.ADD_CUSTOM_REPORTS
    TEMPLATE.ADD_CUSTOM_WIDGETS
    -->
  </button>
</ng-template>

<ng-template
  #actionsTemplate
  let-dataItem="dataItem"
>
  <div class="action-column">
    <ng-template
      [ngTemplateOutlet]="tagsTemplate"
      [ngTemplateOutletContext]="{ tags: getTemplateTags(dataItem), position: 'top-right' }"
    />
  </div>
</ng-template>

<ng-template
  #tagsTemplate
  let-tags="tags"
  let-position="position"
>
  <dpa-tags
    [tags]="tags"
    [position]="position"
  />
</ng-template>
