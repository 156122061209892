<dpa-tooltip
  [tooltipTemplate]="ownerInfo"
  [attr.aria-label]="
    'COMMON_ACTIONS.OWNER_ARIA_LABEL' | translate: { entityType: entityType, name: entityName, owner: createdByDetails?.userInfo }
  "
  [focusEnabled]="true"
  role="tooltip"
>
  <div class="owner-details">
    <span class="initials">
      {{ createdByDetails?.userInfo | initials }}
    </span>
    @if (showOwnerText) {
      <span>
        {{ 'COMMON_MESSAGES.OWNER' | translate }}
      </span>
    }
  </div>
</dpa-tooltip>
<ng-template #ownerInfo>
  <div class="owner-info">
    <h6 class="owner-label">
      {{ 'COMMON_MESSAGES.OWNER' | translate }}
    </h6>
    <div>
      {{ createdByDetails | userInfo }}
    </div>
    @if (createdAt) {
      <div>
        {{ createdAt | formattedDate: DateTimeFormat.MOMENT_MEDIUM_DATETIME_FORMAT }}
      </div>
    }
  </div>
</ng-template>
