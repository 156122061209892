@if (tags) {
  <div class="dpa-card-tags">
    @if (tags.length === 1 || !combineTags) {
      @for (tag of tags; track tag) {
        <div class="tag label mt-x1">
          {{ tag }}
        </div>
      }
    } @else {
      <div class="tags">
        <clr-dropdown>
          <button clrDropdownTrigger>
            <span class="label">{{ 'WIDGET_TEMPLATE.NUM_OF_TAGS' | translate: { num: tags.length } }}</span>
          </button>
          <clr-dropdown-menu
            *clrIfOpen
            [clrPosition]="position"
            class="ph-x2"
          >
            @for (tag of tags; track tag) {
              <div class="label m-x1">
                {{ tag }}
              </div>
            }
          </clr-dropdown-menu>
        </clr-dropdown>
      </div>
    }
  </div>
}
