<div class="btn-group">
  <div
    [class.disabled]="!snapshotChartOptionVisible"
    class="radio btn"
  >
    <input
      [value]="ENTITY_DATA_TYPE[ENTITY_DATA_TYPE.SNAPSHOT]"
      [attr.checked]="selectedDataType === ENTITY_DATA_TYPE.SNAPSHOT ? 'checked' : null"
      [attr.disabled]="snapshotChartOptionVisible ? undefined : 'true'"
      (click)="selectDataType(ENTITY_DATA_TYPE.SNAPSHOT)"
      type="radio"
      name="data-type-btn"
      id="data-type-snapshot"
      tabindex="-1"
    />
    <label
      dpaTabEvent
      [bindTabEvent]="true"
      for="data-type-snapshot"
      role="radio"
    >
      {{ 'WIDGET_CUSTOMIZE.WIDGET_CHART_TREND_MODE_SNAPSHOT' | translate }}
    </label>
  </div>
  <div
    [class.disabled]="!historicalChartOptionVisible"
    class="radio btn"
  >
    <input
      [value]="ENTITY_DATA_TYPE[ENTITY_DATA_TYPE.HISTORICAL]"
      [attr.checked]="selectedDataType === ENTITY_DATA_TYPE.HISTORICAL ? 'checked' : null"
      [attr.disabled]="historicalChartOptionVisible ? undefined : 'true'"
      (click)="selectDataType(ENTITY_DATA_TYPE.HISTORICAL)"
      name="data-type-btn"
      type="radio"
      id="data-type-historical"
      tabindex="-1"
    />
    <label
      dpaTabEvent
      [bindTabEvent]="true"
      for="data-type-historical"
      role="radio"
    >
      {{ 'WIDGET_CUSTOMIZE.WIDGET_CHART_TREND_MODE_HISTORICAL' | translate }}
    </label>
  </div>
</div>
