<div class="type-ahead-container">
  <dpa-type-ahead
    [selectedItem]="selectedSortBy"
    [searchableItems]="sortByOptions"
    [customFormatter]="getLabel"
    [labelFormatter]="getLabel"
    [keyBy]="getItemKey"
    [screenReaderLabelText]="screenReaderLabelText"
    (selectedItemChange)="onChange($event)"
  />
</div>
