@if (useCustomHeaderTemplate) {
  <ng-template #headerTemplate>
    <ng-content select=".accordion-header" />
  </ng-template>
}

<ng-template #contentTemplate>
  <div class="mh-x5">
    <ng-content />
  </div>
</ng-template>
