<div
  [class.compact-gap]="isCompactGap"
  class="star-rating-container"
>
  @for (count of range; track count) {
    <ng-container *ngLet="count <= rating as isStarFilled">
      <cds-icon
        [class.clickable]="!isReadOnly"
        [class.read-only-non-solid]="isReadOnly && !isStarFilled"
        [attr.size]="iconSize"
        [attr.solid]="isStarFilled"
        (click)="onStarClick(count)"
        shape="star"
      />
    </ng-container>
  }
</div>
