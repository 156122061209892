@if (webError) {
  <div>
    @if (!webError.errors?.length) {
      <clr-alert
        [clrAlertType]="'danger'"
        [clrAlertSizeSmall]="true"
      >
        <clr-alert-item>
          <span class="alert-text">
            {{ 'COMMON_MESSAGES.UNKNOWN_ERROR' | translate }}
          </span>
        </clr-alert-item>
      </clr-alert>
    }
    @for (webErrorMessage of webError.errors; track webErrorMessage) {
      <clr-alert
        [clrAlertType]="'danger'"
        [clrAlertSizeSmall]="true"
      >
        <clr-alert-item>
          <span class="alert-text">
            @if (webErrorMessage.code) {
              <!-- prettier-ignore -->
              <span class="web-error-code">
                {{ webErrorMessage.code }}:
              </span>
            }
            <span>{{ webErrorMessage.message }}</span>
          </span>
        </clr-alert-item>
      </clr-alert>
    }
  </div>
}
