<div class="page-number-changer">
  <dpa-turbo-button
    (turboClick)="onPageChange(-1)"
    (keyup.enter)="onPageChange(-1)"
    tabindex="0"
  >
    <cds-icon
      class="clickable left-arrow"
      shape="angle"
      direction="left"
    />
  </dpa-turbo-button>
  <div class="page-number">
    {{ pageNumber + 1 }}
    /
    {{ pageCount }}
  </div>
  <dpa-turbo-button
    (turboClick)="onPageChange(1)"
    (keyup.enter)="onPageChange(1)"
    tabindex="0"
  >
    <cds-icon
      shape="angle"
      direction="right"
      class="clickable right-arrow"
    />
  </dpa-turbo-button>
</div>
