@if (customContentTemplate) {
  <div class="title-wrapper mt-x4">
    <h3 class="mt-0">
      {{ 'COMMON_MESSAGES.SELECT_TEMPLATE' | translate }}
    </h3>
    <ng-template
      [ngTemplateOutlet]="customContentTemplate"
      class="custom-template-button"
    />
  </div>
}

<!-- Search functionality: Start -->
<div class="search-input mt-x4">
  <dpa-local-search
    [placeHolderText]="searchPlaceholder || ('COMMON_ACTIONS.SEARCH' | translate)"
    (onSearch)="onQueryChanged($event)"
    iconClass="search"
  />
  @if (isCardView && orderByArray.length > 0) {
    <dpa-type-ahead
      [placeholderText]="'WIDGET_CUSTOMIZE.WIDGET_CHART_ORDER_BY' | translate"
      [searchableItems]="orderByArray"
      [customFormatter]="orderByFormatter"
      [labelFormatter]="orderByFormatter"
      (selectedItemChange)="onOrderByChange($event)"
      class="order-by-input"
    />
  }
</div>
<!-- Search functionality: End -->

<div class="filters-wrapper mt-x4">
  <!-- Filters : Start -->
  @if (filterTags?.length) {
    <dpa-filter-tags
      [defaultSelectedTags]="[defaultSelectedTag]"
      [filterTags]="filterTags"
      (onFilterChange)="onFilterChange($event)"
    />
  }
  <!-- Filters : End -->

  <!-- Layout Switcher : Start -->
  <dpa-multi-layout-switcher
    [updateParams]="updateUrlLayoutParam"
    [availableLayouts]="availableLayouts"
    [selectedLayout]="selectedLayout"
    (selectedLayoutChange)="onLayoutChange($event)"
  />
  <!-- Layout Switcher : End -->
</div>
<!-- Grid view -->
<div class="template-search-data-grid">
  @if (isCardView) {
    <div class="clr-row">
      @for (row of response.results; track row) {
        <div class="clr-col-xl-4 clr-col-lg-4 clr-col-md-6">
          <ng-template
            [ngTemplateOutlet]="cardTemplate"
            [ngTemplateOutletContext]="{
              row: row,
              styledString: styledStrings[row[searchOn]]
            }"
          />
        </div>
      }
    </div>
    <dpa-data-grid
      [footerMode]="true"
      [enableColumnSelector]="false"
      [pageSize]="pageSize"
      [loading]="isLoading"
      [response]="response"
      [noResultsText]="'COMMON_MESSAGES.NO_RESULTS_FOUND' | translate"
      (pageChange)="onPageChange($event)"
      (sortChange)="onSortChange($event)"
    />
  } @else {
    <div class="mt-x4">
      @if (dataGridColumns?.length) {
        <dpa-data-grid
          [loading]="isLoading"
          [response]="response"
          [sortOns]="sortOns"
          [selectable]="{ single: false, enabled: false }"
          [selectedRows]="selectedRows"
          [pageSize]="pagedRequest.size"
          [noResultsText]="'COMMON_MESSAGES.NO_DATA_AVAILABLE' | translate"
          [columnWidthByKey]="columnWidthByKey"
          [attr.aria-label]="tableName"
          (sortChange)="onSortChange($event)"
          (pageChange)="onPageChange($event)"
          class="valign-cells"
          tabindex="0"
        >
          @for (action of actions; track action) {
            <div
              dpaDataGridSingleRowAction
              [label]="action.label"
              [className]="action.className"
              [hidden]="action.isHidden"
              [disabled]="action.isDisabled"
              (click)="action.onClick($event)"
              (keyup.enter)="action.onClick($event)"
            ></div>
          }
          @for (dataGridColumn of dataGridColumns; track dataGridColumn) {
            <div
              dpaDataGridColumn
              [field]="dataGridColumn.value"
              [sortBy]="dataGridColumn.value"
              [title]="dataGridColumn.label"
              [sortable]="true"
              [className]="dataGridColumn.className"
            >
              <ng-template
                dpaDataGridCell
                let-dataItem
              >
                @if (cellTemplatesByColumnValue[dataGridColumn.value]) {
                  <ng-container
                    [ngTemplateOutlet]="cellTemplatesByColumnValue[dataGridColumn.value]"
                    [ngTemplateOutletContext]="{ dataItem: dataItem, dataGridColumn: dataGridColumn }"
                  />
                } @else {
                  @if (dataGridColumn.name === searchOn && styledStrings[dataItem[searchOn]]) {
                    <div
                      [dpaInnerHtmlLight]="styledStrings[dataItem[searchOn]]"
                      class="matching-text"
                    ></div>
                  } @else {
                    @if (!cellTemplatesByColumnValue[dataGridColumn.value]) {
                      <dpa-decorated-value
                        [value]="dataItem[dataGridColumn.value]"
                        [valueDecorator]="dataGridColumn.valueDecorator"
                      />
                    }
                  }
                }
              </ng-template>
            </div>
          }
        </dpa-data-grid>
      }
    </div>
  }
  <!-- List view -->
</div>
