<div
  [ngClass]="{ 'header-only': onlyHeader, selected: selected }"
  class="card"
>
  <ng-content select="dpa-card-block-header" />
  <div class="card-block pb-x1">
    <div class="dpa-card-header mb-x1">
      <div class="dpa-card-icon mr-x2">
        <ng-content select="dpa-card-icon" />
      </div>
      <div
        [class.has-bookmark-btn]="allowBookmark"
        [class.has-custom-label]="hasCustomLabel"
        class="mt-0 title-container"
        tabindex="0"
      >
        <dpa-ellipsis-with-tooltip>
          {{ header }}
        </dpa-ellipsis-with-tooltip>
        <ng-content select="dpa-custom-tag" />
      </div>
      @if (allowBookmark) {
        <dpa-tooltip
          [attr.aria-label]="(hasBookmark ? 'BOOKMARKS.REMOVE_FROM_BOOKMARKS' : 'BOOKMARKS.ADD_TO_BOOKMARKS') | translate"
          [tooltipText]="(hasBookmark ? 'BOOKMARKS.REMOVE_FROM_BOOKMARKS' : 'BOOKMARKS.ADD_TO_BOOKMARKS') | translate"
          [focusEnabled]="true"
          (click)="onToggleBookmark()"
          (keyup.enter)="onToggleBookmark()"
          class="bookmark-tooltip mr-x2"
          role="tooltip"
        >
          <cds-icon
            [attr.solid]="hasBookmark"
            shape="bookmark"
            size="18"
          />
        </dpa-tooltip>
      }
    </div>
    @if (description) {
      <div class="dpa-card-description">
        <dpa-text-fade
          [maxLines]="descriptionMaxLines"
          [text]="description"
        />
      </div>
    }
    <ng-content select="dpa-card-body" />
    @if (tags.length) {
      <div class="dpa-card-tags mb-x2">
        @for (tagLabel of tags; track tagLabel) {
          <div class="tag label mt-x1">
            {{ tagLabel }}
          </div>
        }
      </div>
    }
  </div>
  <ng-content select="dpa-card-block" />
  <ng-content select="dpa-card-footer" />
</div>
