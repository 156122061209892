<div class="filters-row pv-x2">
  @for (tag of filterTags; track tag) {
    @if (isTagWithoutDropdown(tag.type)) {
      <button
        [ngClass]="filter[tag.name] ? 'label label-info' : 'no-selection'"
        [disabled]="tagsDisabled"
        [hidden]="tag.isHidden"
        (click)="toggleBooleanFilter(tag)"
        type="button"
        class="btn btn-sm btn-outline-primary btn-filter mv-0 mr-x1"
      >
        {{ tag.label | translate }}
      </button>
    } @else {
      <clr-dropdown
        [clrCloseMenuOnItemClick]="false"
        [class.divider]="hasDivider"
        [hidden]="tag.isHidden"
      >
        @if (filter[tag.name]?.selectedSubTags?.length; as selectedCount) {
          <button
            clrDropdownTrigger
            [disabled]="tagsDisabled"
            type="button"
            class="btn btn-sm btn-outline-primary btn-filter label label-info selected-label"
          >
            {{ tag.label | translate }}: {{ 'COMMON_MESSAGES.COUNT_SELECTED' | translate: { count: selectedCount } }}
            <a
              (click)="clearFilter(tag.name)"
              (keydown.enter)="clearFilter(tag.name)"
              class="ml-x1"
            >
              <cds-icon shape="window-close" />
            </a>
          </button>
        } @else {
          <button
            clrDropdownTrigger
            [disabled]="tagsDisabled"
            type="button"
            class="btn btn-sm btn-outline-primary btn-filter no-selection"
          >
            {{ tag.label | translate }}
            <cds-icon
              shape="angle"
              direction="down"
            />
          </button>
        }
        <clr-dropdown-menu
          *clrIfOpen
          class="pb-0"
        >
          <div class="dropdown-subtag-wrapper">
            @for (subtag of tag.subtags; track subtag) {
              @if (filter[tag.name] && filter[tag.name].selectedSubTags.includes(subtag.name)) {
                <button class="label label-light-blue quick-filters label-info">
                  {{ subtag.label }}
                  <a
                    clrDropdownItem
                    (click)="toggleFilter(subtag.name, tag)"
                    (keyup.enter)="toggleFilter(subtag.name, tag)"
                    class="ml-x1 drop-down-option-clear clickable"
                  >
                    <cds-icon
                      shape="window-close"
                      size="12"
                    />
                  </a>
                </button>
              } @else {
                <button
                  clrDropdownItem
                  (click)="toggleFilter(subtag.name, tag)"
                  class="label label-light-blue quick-filters clickable"
                >
                  {{ subtag.label }}
                </button>
              }
            }
          </div>
          <div class="dropdown-buttons">
            <button
              clrDropdownItem
              (click)="selectAllSubTags(tag)"
              class="btn btn-link"
            >
              {{ 'REPORT_CUSTOMIZE.REPORT_FILTERS_SELECT_ALL' | translate }}
            </button>
            <button
              clrDropdownItem
              [clrDisabled]="!filter[tag.name]"
              (click)="clearFilter(tag.name)"
              class="btn btn-link"
            >
              {{ 'REPORT_CUSTOMIZE.REPORT_FILTERS_CLEAR_ALL' | translate }}
            </button>
            <button
              clrDropdownTrigger
              class="btn btn-link"
            >
              {{ 'COMMON_ACTIONS.CLOSE' | translate }}
            </button>
          </div>
        </clr-dropdown-menu>
      </clr-dropdown>
    }
  }
</div>
