<div class="container">
  @for (facetCount of facetCounts; track facetCount?.fieldValue) {
    <clr-dropdown [clrCloseMenuOnItemClick]="false">
      <ng-container *ngLet="!!facetCount.subFacetCounts?.length as hasSubFacets">
        <button
          *ngLet="selectedTagsMap.get(facetCount.fieldValue)?.size as selectedCount"
          clrDropdownTrigger
          [class.label-info]="isTagSelected(facetCount.fieldValue)"
          (click)="!hasSubFacets && toggleTag(facetCount.fieldValue)"
          class="label clickable filter-tag top-level"
        >
          <span>{{ facetCount.fieldValue }}</span>
          @if (selectedCount) {
            <span>: {{ 'COMMON_MESSAGES.COUNT_SELECTED' | translate: { count: selectedCount } }}</span>
          }
          @if (isTagSelected(facetCount.fieldValue)) {
            <cds-icon
              (click)="clearAllSubTags(facetCount.fieldValue, $event)"
              shape="times"
              size="14"
            />
          } @else if (hasSubFacets) {
            <cds-icon
              shape="angle"
              direction="down"
            />
          }
        </button>
        @if (hasSubFacets) {
          <clr-dropdown-menu *clrIfOpen>
            @for (subFacetCount of facetCount.subFacetCounts; track subFacetCount?.fieldValue) {
              <span
                clrDropdownItem
                [class.label-info]="isTagSelected(facetCount.fieldValue, subFacetCount.fieldValue)"
                (click)="toggleSubTag(facetCount.fieldValue, subFacetCount.fieldValue)"
                class="label clickable filter-tag"
              >
                <span>{{ subFacetCount.fieldValue }}</span>
                @if (isTagSelected(facetCount.fieldValue, subFacetCount.fieldValue)) {
                  <cds-icon
                    shape="times"
                    size="14"
                  />
                }
              </span>
            }
            <div class="dropdown-options">
              <button
                (click)="selectAllSubTags(facetCount.fieldValue)"
                class="btn btn-link"
              >
                {{ 'REPORT_CUSTOMIZE.REPORT_FILTERS_SELECT_ALL' | translate }}
              </button>
              <button
                [disabled]="!isTagSelected(facetCount.fieldValue)"
                (click)="clearAllSubTags(facetCount.fieldValue)"
                class="btn btn-link"
              >
                {{ 'REPORT_CUSTOMIZE.REPORT_FILTERS_CLEAR_ALL' | translate }}
              </button>
            </div>
          </clr-dropdown-menu>
        }
      </ng-container>
    </clr-dropdown>
  }
</div>
