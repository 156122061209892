<h4>
  {{ header }}
  @if (tooltip) {
    <dpa-tooltip [tooltipText]="tooltip">
      <cds-icon
        shape="info-circle"
        class="is-dark-midtone-gray"
        size="24"
      />
    </dpa-tooltip>
  }
</h4>
<dpa-category-selector-dropdown
  [selectedCategory]="selectedCategory"
  [integrationCategories]="integrationCategories"
  (selectedCategoryChange)="selectedCategoryChange.emit($event)"
/>
@if (description) {
  <p>
    {{ description }}
  </p>
}
