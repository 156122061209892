<div
  [class.expanded]="expanded"
  class="panel"
>
  @if (showHeader) {
    <ng-container
      [ngTemplateOutlet]="headerTemplate || headerDefaultTemplate"
      [ngTemplateOutletContext]="{
        toggleExpand: toggleExpand,
        expanded: expanded,
        toggleable: toggleable
      }"
    />
  }
  <div class="panel-body">
    @if (expanded) {
      <ng-content />
    }
  </div>
</div>

<ng-template #headerDefaultTemplate>
  <div
    dpaTabEvent
    [class.toggleable]="toggleable"
    (click)="toggleExpand()"
    (keyup.enter)="toggleExpand()"
    class="panel-header"
  >
    <div class="header-labels">
      <span
        [textContent]="headerMainText"
        class="header-main-text"
      ></span>
      <span
        [textContent]="headerSubText"
        class="header-sub-text"
      ></span>
    </div>
    @if (toggleable) {
      <div class="header-actions">
        <cds-icon
          shape="angle"
          size="20"
          direction="down"
        />
      </div>
    }
  </div>
</ng-template>
