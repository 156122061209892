<div class="button-container pt-x3 pb-x1">
  <button
    [clrLoading]="isNextLoading"
    [disabled]="isReadOnlyMode || isNextDisabled"
    [ngClass]="isFinalStep && !isStandaloneMode ? 'btn-primary' : 'btn-outline'"
    (click)="onNextClick()"
    class="btn"
    type="submit"
  >
    {{ (this.isStandaloneMode || isFinalStep ? 'COMMON_ACTIONS.SAVE' : 'COMMON_ACTIONS.NEXT') | translate }}
  </button>
  @if (showReset) {
    <button
      [clrLoading]="(resetModalTitle || resetModalDesc) && isResetLoading"
      [disabled]="isReadOnlyMode || isResetDisabled"
      (click)="onResetClick()"
      class="btn btn-link"
      type="submit"
    >
      {{ 'COMMON_ACTIONS.RESET' | translate }}
    </button>
  }
  <ng-container [ngTemplateOutlet]="textTemplate" />
</div>
@if (isNextModalOpen) {
  <dpa-modal
    [modalOpen]="true"
    (onModalClose)="showNextModal(false)"
  >
    <dpa-modal-title>
      {{ nextModalTitle }}
    </dpa-modal-title>
    <div class="modal-body">
      {{ nextModalDesc }}
    </div>
    <div class="modal-footer">
      <div class="button-group">
        <button
          (click)="showNextModal(false)"
          class="btn btn-outline"
        >
          {{ 'COMMON_ACTIONS.CANCEL' | translate }}
        </button>
        <button
          [clrLoading]="isNextLoading"
          (click)="onNext()"
          class="btn btn-primary"
        >
          {{ (this.isStandaloneMode || isFinalStep ? 'COMMON_ACTIONS.SAVE' : 'COMMON_ACTIONS.NEXT') | translate }}
        </button>
      </div>
    </div>
  </dpa-modal>
}
@if (isResetModalOpen) {
  <dpa-modal
    [modalOpen]="true"
    (onModalClose)="showResetModal(false)"
  >
    <dpa-modal-title>
      {{ resetModalTitle }}
    </dpa-modal-title>
    <div class="modal-body">
      {{ resetModalDesc }}
    </div>
    <div class="modal-footer">
      <div class="button-group">
        <button
          (click)="showResetModal(false)"
          class="btn btn-outline"
        >
          {{ 'COMMON_ACTIONS.CANCEL' | translate }}
        </button>
        <button
          [clrLoading]="isResetLoading"
          (click)="onReset()"
          class="btn btn-primary"
        >
          {{ 'COMMON_ACTIONS.RESET' | translate }}
        </button>
      </div>
    </div>
  </dpa-modal>
}
