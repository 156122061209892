@if (tooltip) {
  <dpa-tooltip
    [tooltipText]="tooltip.textKey | translate"
    [focusEnabled]="true"
  >
    @switch (tooltip.severity) {
      @case (TooltipSeverity.INFO) {
        <cds-icon
          class="is-info"
          shape="info-circle"
          size="24"
        />
      }
      @case (TooltipSeverity.WARNING) {
        <cds-icon
          status="warning"
          shape="exclamation-triangle"
          size="24"
        />
      }
      @case (TooltipSeverity.DANGER) {
        <cds-icon
          status="danger"
          shape="exclamation-triangle"
          size="24"
        />
      }
    }
  </dpa-tooltip>
}
