import { Slot } from "./slot.js";
export { Slot };
export const {
  bind,
  noContext
} = Slot;
// Like global.setTimeout, except the callback runs with captured context.
export { setTimeoutWithContext as setTimeout };
function setTimeoutWithContext(callback, delay) {
  return setTimeout(bind(callback), delay);
}
// Turn any generator function into an async function (using yield instead
// of await), with context automatically preserved across yields.
export function asyncFromGen(genFn) {
  return function () {
    const gen = genFn.apply(this, arguments);
    const boundNext = bind(gen.next);
    const boundThrow = bind(gen.throw);
    return new Promise((resolve, reject) => {
      function invoke(method, argument) {
        try {
          var result = method.call(gen, argument);
        } catch (error) {
          return reject(error);
        }
        const next = result.done ? resolve : invokeNext;
        if (isPromiseLike(result.value)) {
          result.value.then(next, result.done ? reject : invokeThrow);
        } else {
          next(result.value);
        }
      }
      const invokeNext = value => invoke(boundNext, value);
      const invokeThrow = error => invoke(boundThrow, error);
      invokeNext();
    });
  };
}
function isPromiseLike(value) {
  return value && typeof value.then === "function";
}
// If you use the fibers npm package to implement coroutines in Node.js,
// you should call this function at least once to ensure context management
// remains coherent across any yields.
const wrappedFibers = [];
export function wrapYieldingFiberMethods(Fiber) {
  // There can be only one implementation of Fiber per process, so this array
  // should never grow longer than one element.
  if (wrappedFibers.indexOf(Fiber) < 0) {
    const wrap = (obj, method) => {
      const fn = obj[method];
      obj[method] = function () {
        return noContext(fn, arguments, this);
      };
    };
    // These methods can yield, according to
    // https://github.com/laverdet/node-fibers/blob/ddebed9b8ae3883e57f822e2108e6943e5c8d2a8/fibers.js#L97-L100
    wrap(Fiber, "yield");
    wrap(Fiber.prototype, "run");
    wrap(Fiber.prototype, "throwInto");
    wrappedFibers.push(Fiber);
  }
  return Fiber;
}
