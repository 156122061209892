<ul class="item-list">
  @for (tmpl of templates || []; track tmpl; let last = $last) {
    <li class="item">
      <div class="separator">
        <div class="dot"></div>
        @if (!last) {
          <div class="connector"></div>
        }
      </div>
      <ng-container [ngTemplateOutlet]="tmpl" />
    </li>
  }
</ul>
