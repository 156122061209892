<div
  dpaFileDragDrop
  [fileOfType]="allowedFileType"
  [allowMultiple]="allowMultiple"
  [ngClass]="['file-uploader-container', styleClasses]"
  (onTooManyFiles)="onTooMany()"
  (onWrongFileType)="onWrongType()"
  (onFileDrop)="addFiles($event)"
  class="file-uploader-container"
>
  @if (showBgImage) {
    <div class="bg-image custom-actions-empty-state"></div>
  }
  @if (helperString) {
    <div
      [dpaInnerHtmlLight]="helperString"
      class="drag-info mb-x2"
    ></div>
  }
  <div class="uploader-button-row">
    <label class="btn btn-sm btn-outline">
      {{ 'COMMON_MESSAGES.CHOOSE_FILE' | translate | uppercase }}
      <input
        #fileInput
        [accept]="allowedFileType"
        [attr.multiple]="allowMultiple ? true : null"
        (change)="onFileChange($event)"
        type="file"
        class="hidden-input"
      />
    </label>
    <button
      [attr.disabled]="getIsUploadButtonDisabled() ? 'true' : undefined"
      (click)="uploadSelectedFiles()"
      class="btn btn-sm btn-primary"
    >
      {{ 'COMMON_MESSAGES.UPLOAD' | translate }}
    </button>
  </div>
  @if (warningMessage) {
    <clr-alert
      [clrAlertType]="'danger'"
      [clrAlertSizeSmall]="true"
      [clrAlertClosable]="false"
    >
      <clr-alert-item>
        <span class="alert-text">
          {{ warningMessage }}
        </span>
      </clr-alert-item>
    </clr-alert>
  }
  <div class="file-details">
    @if (selectedFiles.length) {
      <div class="selected-files-row">
        <dpa-tooltip [tooltipTemplate]="selectedFilesTemplate">
          <div>
            {{ 'COMMON_MESSAGES.COUNT_FILES_SELECTED' | translate: { count: selectedFiles.length } }}
          </div>
        </dpa-tooltip>
        <cds-icon
          (click)="clearFiles()"
          shape="trash"
          class="clickable ml-x4"
        />
      </div>
    }
  </div>
  <dpa-web-error [webError]="webError" />
  @if (isUploading) {
    <div class="spinner-container-centered">
      <span class="spinner spinner-md"></span>
    </div>
  }
</div>

<ng-template #selectedFilesTemplate>
  <div class="selected-files-container">
    @for (file of selectedFiles; track file) {
      <div>
        {{ file.name }}
      </div>
    }
  </div>
</ng-template>
