<clr-dropdown class="category-dropdown">
  <button
    clrDropdownTrigger
    class="btn btn-link"
  >
    @if (selectedCategory) {
      {{ selectedCategory.fullLabel }}
    } @else {
      {{ 'COMMON_MESSAGES.CATEGORY' | translate }}
    }
    <cds-icon
      shape="angle"
      direction="down"
    />
  </button>
  <ng-template
    [clrIfOpen]="isMainMenuOpen"
    (clrIfOpenChange)="onMainMenuOpenChange($event)"
  >
    <clr-dropdown-menu [clrPosition]="CLARITY_TOOLTIP_POSITION.RIGHT_TOP">
      @for (categoryGroup of integrationCategories; track categoryGroup) {
        <clr-dropdown>
          <button
            clrDropdownTrigger
            [class.parent-active]="isIntegrationHighlighted(categoryGroup.integration)"
          >
            {{ categoryGroup.integration.label }}
          </button>
          <ng-template
            [clrIfOpen]="isSubMenuOpen(categoryGroup.integration)"
            (clrIfOpenChange)="onSubMenuOpenChange($event, categoryGroup.integration)"
          >
            <clr-dropdown-menu>
              @for (category of categoryGroup.categories; track category) {
                <button
                  clrDropdownItem
                  [class.active]="category.isSameAs(selectedCategory)"
                  (click)="onSelectedCategoryChange(category)"
                >
                  {{ category.entity.label }}
                </button>
              }
            </clr-dropdown-menu>
          </ng-template>
        </clr-dropdown>
      }
    </clr-dropdown-menu>
  </ng-template>
</clr-dropdown>
