<div class="roles">
  @for (role of roles; track role) {
    <div [ngClass]="role.getNameLowercase()">
      <dpa-tooltip
        [tooltipText]="role.label"
        [focusEnabled]="true"
      >
        <cds-icon
          [attr.shape]="role.getNameLowercase() | dpaIconName: '' : '-solid'"
          class="role-icon mr-x1"
        />
      </dpa-tooltip>
    </div>
  }
</div>
