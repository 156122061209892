@if (isFullScreen) {
  <dpa-full-page [isOpen]="true">
    <div class="config-success-container clr-col-12">
      <div class="clr-row clr-justify-content-center">
        <div
          class="success-img"
          role="img"
        ></div>
      </div>
      <div class="clr-row clr-justify-content-center">
        <h2>
          {{ enabledText }}
        </h2>
      </div>
    </div>
  </dpa-full-page>
} @else {
  <div class="config-success-container clr-col-12">
    <div class="clr-row clr-justify-content-center">
      <div
        class="success-img"
        role="img"
      ></div>
    </div>
    <div class="clr-col">
      <h2>
        {{ enabledText }}
      </h2>
      @if (enabledDescription) {
        <div class="description-container">
          <p>{{ enabledDescription }}</p>
          <button
            [routerLink]="btnRouterLink"
            [queryParams]="btnRouterQueryParams"
            class="btn btn-primary"
          >
            {{ 'SOLUTIONS.VIEW_THRESHOLDS' | translate }}
          </button>
        </div>
      }
    </div>
  </div>
}
