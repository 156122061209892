@if (accordionWizardConfig) {
  <dpa-accordion [class.single-accordion-step]="accordionSteps.length === 1">
    @for (accordionStep of accordionSteps; track stepIndex; let stepIndex = $index) {
      <div
        dpaAccordionRow
        [title]="accordionStep.title"
        [description]="accordionStep.description"
        [expanded]="accordionWizardConfig.openStepIndices.has(stepIndex)"
        [disabled]="!accordionWizardConfig.enabledStepIndices.has(stepIndex)"
        (click)="toggleStepByIndex(stepIndex)"
        (keyup.enter)="toggleStepByIndex(stepIndex)"
      >
        @if (accordionStep.headerTemplate) {
          <ng-template dpaAccordionRowHeader>
            <ng-container [ngTemplateOutlet]="accordionStep.headerTemplate" />
          </ng-template>
        }
        <ng-template dpaAccordionRowContent>
          @if (preMountContent || accordionWizardConfig.openStepIndices.has(stepIndex)) {
            <ng-container [ngTemplateOutlet]="accordionStep.contentTemplate" />
          }
        </ng-template>
      </div>
    }
  </dpa-accordion>
}
