{{ displayValue }}
@if (remainingValuesButtonLabel) {
  <dpa-tooltip
    [attr.aria-label]="tooltipText"
    [tooltipText]="tooltipText"
    aria-haspopup="true"
  >
    <button
      (click)="onRemainingValuesBtnClick()"
      class="btn btn-link btn-sm remaining-values-btn"
    >
      {{ remainingValuesButtonLabel }}
    </button>
  </dpa-tooltip>
}
