<div class="btn-group">
  @for (layoutType of availableLayouts; track layoutType) {
    <div class="radio btn">
      @if (layoutSwitches[layoutType]; as layoutSwitch) {
        <input
          [id]="layoutSwitch.id"
          [checked]="layoutSwitch.id === selectedLayout"
          (change)="changeLayout(layoutType)"
          type="radio"
          name="multi-layout-switch"
        />
        <label [for]="layoutSwitch.id">
          <cds-icon
            [attr.shape]="layoutSwitch.icon"
            size="24"
          />
        </label>
      }
    </div>
  }
</div>
