<div class="radio-container">
  @for (option of options; track option) {
    <label class="radio-label clickable ml-x2">
      <!-- Set `disabled` to null for falsy condition to remove `disabled` attribute. -->
      <input
        [name]="uniqueId"
        [formControl]="formControl"
        [value]="option"
        [attr.disabled]="readonly || null"
        type="radio"
      />
      <div class="ml-x1">
        {{ option.label }}
      </div>
    </label>
  }
</div>
