@if (!readonly) {
  <div class="date-range-container clr-row">
    <div
      [class.clr-col-4]="splitCol"
      class="date-typeahead-container clr-col"
    >
      <dpa-type-ahead
        [selectedItem]="selectedTrendSpanOption"
        [searchableItems]="trendSpanOptions"
        [customFormatter]="typeAheadformatter"
        [labelFormatter]="typeAheadformatter"
        [sortResults]="false"
        [placeholderText]="'COMMON_MESSAGES.DATE_RANGE' | translate"
        (selectedItemChange)="onTrendSpanOptionChange($event)"
        (onQueryChange)="onQueryChange($event)"
      />
    </div>
    @if (selectedTrendSpanOption === customTrendSpanOption) {
      <div
        [class.clr-col-4]="splitCol"
        class="clr-col"
      >
        <div class="date-time-container">
          <dpa-date-time-picker
            [pickerType]="DATE_TIME_PICKER_TYPE.DATE_TIME_RANGE"
            [dates]="defaultCustomDateRange"
            [min]="minStartDate"
            [max]="maxEndDate"
            [maxRangeInMillis]="maxRangeInMillis"
            [inputId]="'date-range-picker'"
            (onSelectDate)="onCustomRangeChange($event)"
            class="date-text-field"
          />
        </div>
      </div>
    }
  </div>
} @else {
  @if (selectedTrendSpanOption === customTrendSpanOption) {
    <div>
      <ng-container *ngTemplateOutlet="readonlyCalendarIcon" />
      <span>
        {{ trendDateRange?.startDateMillis | formattedDate }}
        -
      </span>
      <span>
        {{ trendDateRange?.endDateMillis | formattedDate }}
      </span>
    </div>
  } @else {
    @if (selectedTrendSpanOption?.label) {
      <div>
        <ng-container *ngTemplateOutlet="readonlyCalendarIcon" />
        {{ selectedTrendSpanOption?.label }}
      </div>
    }
  }
}
@if (customTimePeriodHelpText && selectedTrendSpanOption === customTrendSpanOption) {
  <div class="clr-subtext">
    {{ customTimePeriodHelpText }}
  </div>
}
<ng-template #readonlyCalendarIcon>
  @if (showReadonlyCalendarIcon) {
    <cds-icon
      shape="calendar"
      size="18"
      class="calendar-icon"
    />
  }
</ng-template>
