@if (dataAccessPolicies?.length > 0) {
  <div>
    <ng-container *ngLet="dataAccessPolicies[0].isDefaultPolicyAssigned(defaultPermitAllSystemId) as isDefaultPolicyAssigned">
      <span [class.gray-out]="isDefaultPolicyAssigned">
        {{ isDefaultPolicyAssigned ? ('ADMINISTRATOR.PERMIT_ALL' | translate) : dataAccessPolicies[0].name }}
      </span>
      @if (isDefaultPolicyAssigned) {
        <dpa-tooltip
          [tooltipText]="'ADMINISTRATOR.PERMIT_ALL_INFO' | translate"
          class="ml-x1"
        >
          <cds-icon
            shape="info-circle"
            size="20"
          />
        </dpa-tooltip>
      }
    </ng-container>
  </div>
}
